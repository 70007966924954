
import { defineComponent, computed, reactive, toRefs } from 'vue'
import { getEncCourse } from '@/api/index'
export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array
    },
    data: {
      type: Array
    },
    title: {
      type: String
    }
  },
  emits: ['cancel'],
  setup (props, context) {
    const dialogVisible = computed(() => props.visible)
    const handleCancel = () => {
      context.emit('cancel')
    }
    // const tableData = reactive({
    //   columns: props.columns,
    //   data: props.data
    // })
    return {
      dialogVisible,
      handleCancel
      // ...toRefs(tableData)
    }
  }
})
