
import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue'
import { overviewList, deleteOverview, getOverviewSchool } from '@/api/index'
import mangerPageHeader, { BtnProps } from '@/components/btn-and-search.vue'
import { btnAndSearchHooks } from '@/hooks/btn-and-search-hooks'
import CreateOverview from './createOverview.vue'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { showConfirm, setPage } from '@/hooks/common-hooks'
import { useStore } from 'vuex'
import companyDialog from '@/components/dialog-table.vue'
const btnList: BtnProps[] = [
  {
    id: 1,
    btnName: '总览列表'
  },
  {
    id: 2,
    btnName: '新建总览'
  }
]
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
    // align: 'center'
  },
  {
    title: '总览名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '创建人',
    dataIndex: 'creator',
    key: 'creator'
  },
  {
    title: '添加日期',
    dataIndex: 'add_time',
    key: 'add_time'
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right'
  }
]
interface ListProps {
  search: string; // 搜索关键字(含对学校名，联系人，联系人手机）
  page: number;
  pagesize: number;
}
interface PaginationProps {
  current: number;
  pageSize: number;
  total: number;
}
const pagination: PaginationProps = {
  current: 1,
  pageSize: 10,
  total: 0
}
const companyColums = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: '单位名称',
    dataIndex: 'name',
    key: 'name'
  }
]
export default defineComponent({
  name: 'OverViewManger',
  components: {
    mangerPageHeader,
    CreateOverview,
    companyDialog
  },
  setup () {
    const store = useStore()
    // 打开所属课程弹框
    const courseDialogVisible = ref<boolean>(false)
    const companyData = ref([])
    const dialogRef = ref()
    const tableData = reactive({
      columns,
      data: [],
      pagination: { ...pagination }
    })
    const searchInput = ref('')
    // 查询所属单位
    const fetchCompanyHttp = (id: number) => {
      getOverviewSchool({
        overview_id: id
      }).then(res => {
        companyData.value = res.data.map(
          (item: any) => {
            item.key = item.id
            return item
          })
      })
    }
    const openCourse = (id: number) => {
      courseDialogVisible.value = true
      // const { fetchCourseHttp } = dialogRef.value
      fetchCompanyHttp(id)
    }
    // 关闭所属课程弹框
    const dialogCancle = () => {
      courseDialogVisible.value = false
    }
    // 获取总览列表
    const fetchOverviewHttp = (isDelete?: boolean) => {
      overviewList({
        search: searchInput.value.trim(),
        page: tableData.pagination.current,
        pagesize: tableData.pagination.pageSize
      }).then((res) => {
        tableData.data = res.data.map((item: { key: number; id: number }) => {
          item.key = item.id
          return item
        })
        tableData.pagination.total = res.result ? res.result : 0
        if (isDelete && tableData.data.length === 0) {
          tableData.pagination.current = setPage(tableData.pagination)
          fetchOverviewHttp()
        }
      })
    }
    // 搜索
    const fetchSearch = () => {
      tableData.pagination.current = 1
      fetchOverviewHttp()
    }
    const changeData = (pagination: PaginationProps) => {
      tableData.pagination.current = pagination.current
      fetchOverviewHttp()
      console.log(pagination.current)
      console.log(pagination.total, '总数')
    }
    const { changeIndex, btnIndex } = btnAndSearchHooks(fetchOverviewHttp)
    // 跳转编辑页面
    const router = useRouter()
    const goEditor = (id: number) => {
      router.push({ name: 'CreateOverview', params: { id } })
    }
    // 删除总览
    const deleteItem = (id: number) => {
      console.log(id, 'row')
      deleteOverview({
        overview_id: id
      }).then(res => {
        message.success('删除成功')
        fetchOverviewHttp(true)
      })
    }
    const deleteAllView = (id: number) => {
      showConfirm({
        content: '此操作不可逆,确定要删除该数据吗?',
        callBack: () => {
          deleteItem(id)
        }
      })
    }
    onMounted(() => {
      fetchOverviewHttp()
    })
    return {
      btnList,
      changeIndex,
      btnIndex,
      ...toRefs(tableData),
      searchInput,
      fetchSearch,
      changeData,
      goEditor,
      deleteAllView,
      store,
      companyData,
      courseDialogVisible,
      companyColums,
      dialogCancle,
      dialogRef,
      openCourse
    }
  }
})
