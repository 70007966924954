import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")
  const _component_a_modal = _resolveComponent("a-modal")

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.dialogVisible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialogVisible = $event)),
    title: _ctx.title,
    footer: null,
    onCancel: _ctx.handleCancel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_table, {
        class: "table",
        columns: _ctx.columns,
        "data-source": _ctx.data
      }, null, 8, ["columns", "data-source"])
    ]),
    _: 1
  }, 8, ["visible", "title", "onCancel"]))
}